import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { RWebShare } from "react-web-share";
import IosShareIcon from '@mui/icons-material/IosShare';
import { SocialIcon } from "react-social-icons";
import { AiOutlineRight } from 'react-icons/ai';
import { getUser,userViewCount} from "../../firebase/firebase-calls";
import { useEffect, useState } from "react";
import { followUser, unfollowUser } from "../../firebase/firebase-calls";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ToggleEventModal from "components/ToggleEventModal";


import { auth } from "../../firebase/firebase";


function formatAMPM(date1, date2) {
  var hours1 = date1.getHours();
  var hours2 = date2.getHours();
  var ampm1 = hours1 >= 12 ? 'pm' : 'am';
  hours1 = hours1 % 12;
  hours1 = hours1 ? hours1 : 12; // the hour '0' should be '12'

  console.log(hours2);
  var ampm2 = hours2 >= 12 ? 'pm' : 'am';
  hours2 = hours2 % 12;
  hours2 = hours2 ? hours2 : 12; // the hour '0' should be '12'

  var strTime = hours1 + ' ' + ampm1 + ' - ' + hours2 + ' ' + ampm2;
  return strTime;
}



export default function OtherProfile() {
  const { profileID } = useParams();
  const { allUsers } = useSelector((state) => state.allUsers);
  const userData = {"uid": profileID}
  const [otherUser, setOtherUser] = useState([])
  const {allCircles} = useSelector((State) => State.allCircles);
  const options = { month: 'long', day: 'numeric' , hour: 'numeric', minute: 'numeric'};
  const [website, setWebsite] = useState("");
  const { user } = useSelector((state) => state.user);
  const months = ["JAN", "FEB", "MAR", "APR", "May", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];

  const currentDate = new Date();
  const todayDate = new Date(currentDate.setDate(currentDate.getDate() -1));


  const joinedCircles = otherUser?.joinedCircle?.map(({ uid }) => uid);

  const joinedCircle = allCircles?.filter(
    (circle) => joinedCircles?.includes(circle.circleID)
  ); 

  const upcoming = joinedCircle?.filter(
    (circle) => (new Date(circle?.date?.seconds*1000) >= todayDate) && (circle?.archive != true)
  ); 
  const past = joinedCircle?.filter(
    (circle) => new Date(circle?.date?.seconds*1000) < todayDate && (circle?.archive != true)
  ); 
  const filteredCircles = allCircles?.filter(
    (circle) => circle.circleCreator === otherUser?.uid && (circle?.archive != true)
  );





  useEffect(
    () => {
      if (otherUser.length == 0) {
        getUser(userData, setOtherUser);
        setWebsite(`https://disko.rsvp/profile/${userData?.uid} `)
        console.log("User Fetched!")
        userViewCount(userData?.uid);
      }
     
    },
     
    // eslint-disable-next-line
    [otherUser]
  );


  const handleFollow = (currentUser, otherUser) => {
    // const isFollowing = user?.following?.some(
    //   (user) => user === otherUser?.uid
    // );
    console.log("otherUser" + otherUser?.uid);
    user?.following?.some((id) => id === otherUser?.uid)
      ? unfollowUser(currentUser, otherUser)
      : followUser(currentUser, otherUser);
  };

  return (
<div className="blue_bg w-full overflow-hidden">
      <div className=" h-full">
      <section className="relative w-full h-full md:w-[630px] flex mx-auto blue_bg">
          <div className="relative md:block mb-8 md:mb-24 w-full mx-auto user_bg md:mt-24 glow rounded-md">

          <img
            src={otherUser?.coverPic ? otherUser.coverPic : "https://firebasestorage.googleapis.com/v0/b/jooby-b9791.appspot.com/o/images%2Fundefined%2Fuser_default.png?alt=media&token=7df8b561-4914-4742-9bd6-0bccbf0e27af"}
            alt="cover"
            className="absolute flex left-6 top-16 mx-auto h-[66px] w-[66px] object-cover rounded-full md:mt-8 md:rounded-full "
          />
            <div className="absolute flex left-20 top-20">
            <p className="z-[1] text-2xl ml-6 text-white font-bold fontInter">{otherUser?.name}</p>
            </div>
            <div className="flex absolute bottom-[88%] right-8 rounded-lg z-[1] space-x-4">
              <RWebShare
                data={{
                  text: "RSVP for ",
                  url: website,
                  title: "Disko",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div className="bg-white relative right-0 bottom-0 text-neutral-800 rounded-full">
                <button className="h-[38px] w-[38px]"><IosShareIcon></IosShareIcon></button>
                </div>
              </RWebShare>

          </div>

          <div className="absolute left-20 top-28 mx-auto flex text-white text-sm flex-col items-center gap-2 md:w-[630px]">
          {/* <img
            src={user?.photoURL}
            alt="user-dp"
            className="md:h-18 lg:h-18 aspect-square h-14 w-fit rounded-full object-cover xl:h-24"
          /> */}

        
          <p className="flex w-full ml-12">{otherUser?.bio}</p>
          <p className="text-sm sm:text-base">{otherUser?.website}</p>

          { /* <p className="text-sm sm:text-base">Status: {userData?.status}</p> */ }

        </div>

        <div className="absolute top-48 md:w-[630px] md:h-[20vh] mx-auto blue_bg">
        <div className="h-full w-[100vw] md:w-[630px]">
  <ToggleEventModal upcoming={upcoming} past={past} my_events={filteredCircles} ></ToggleEventModal>
  </div>



{/* <button onclick="buttonHandler()" title="Contact Sale"
class="fixed z-90 bottom-10 right-8 bg-blue-600 w-20 h-20 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300">&#9993;</button> */}
  </div>
</div>


</section>

{ /* Area for social icons placeholder */ }
{/* 
    <div className="flex flex-row mt-4 md:w-[630px] md:mx-auto">
    {userData?.Instagram || userData?.Snapchat || userData?.LinkedIn || userData?.Website || userData?.Discord ? (<div></div>) : (<div className="text-white ml-6 text-sm">Add socials using edit!</div>)}

      {userData?.Instagram && userData?.Instagram?.length > 0 && (<div class="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Instagram} />
        </div>)}
      {userData?.Snapchat && userData?.Snapchat?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Snapchat} />
      </div>
      )}

      {userData?.LinkedIn && userData?.LinkedIn?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.LinkedIn} />
      </div>
      )}

      {userData?.Website && userData?.Website?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Website} />
      </div>)}
      {userData?.Discord && userData?.Discord?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Discord} />
      </div>)}
    </div>
      */ }
</div>
{/*     
    <ul className="mt-5 mb-16 md:mb-24">
       
        {userData?.socials?.map((links) => (
           <SocialIcon url = {links}/>
        ))}

        {showSocial && <div><input type="text" placeholder={"Social Link"}
                onChange={(e) => { setSocialData(e.target.value)}}></input>
                <button
                className="absolute right-4 rounded-full border-none bg-slate-100 p-1.5 text-2xl text-gray-600 shadow-md hover:cursor-pointer hover:brightness-95 "
                onClick={addSocial}
              >
                <FiEdit2 />
          </button></div>}

       {(!showSocial) && <button
            className="absolute right-4 rounded-full border-none bg-slate-100 p-1.5 text-2xl text-gray-600 shadow-md hover:cursor-pointer hover:brightness-95 "
            onClick={() => setShowSocial((prev) => !prev)}
          >
            <FiEdit3 />
      </button>}
</ul> */}

    </div>
  );
}
