import { useEffect, useState } from "react";
import { FiEdit3, FiTrash, FiCheck, FiHeart, FiBookmark } from "react-icons/fi";
import { RiInboxArchiveLine, RiInboxUnarchiveLine } from "react-icons/ri";
import { auth } from "../firebase/firebase";
import { Comment } from "./components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { logReactionAlarm, logReactionEyes, logReactionFire } from "../firebase/firebase-calls";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

// import {
//   archivePost,
//   bookmarkPost,
//   deletePost,
//   dislikePost,
//   editPost,
//   likePost,
//   postComment,
//   unarchivePost,
//   undoBookmarkPost,
//   uploadImage,
// } from "../firebase/firebase-calls";
// import {
//   FaHeart,
//   FaRegCommentAlt,
//   FaRegBookmark,
//   FaBookmark,
// } from "react-icons/fa";

function formatAMPM(date1, date2) {
  var hours1 = date1.getHours();
  var hours2 = date2.getHours();
  var ampm1 = hours1 >= 12 ? 'pm' : 'am';
  hours1 = hours1 % 12;
  hours1 = hours1 ? hours1 : 12; // the hour '0' should be '12'

  var ampm2 = hours2 >= 12 ? 'pm' : 'am';
  hours2 = hours2 % 12;
  hours2 = hours2 ? hours2 : 12; // the hour '0' should be '12'

  var strTime = hours1 + ' ' + ampm1 + ' - ' + hours2 + ' ' + ampm2;
  return strTime;
}


export default function Post({ post }) {
  const options = { month: 'long', day: 'numeric' };
  const months = ["JAN", "FEB", "MAR", "APR", "May", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];

  const currentUser = auth?.currentUser;
  const { allUsers } = useSelector((state) => state.allUsers);
  const { user } = useSelector((state) => state.user);
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableComments, setEnableComments] = useState(false);
  const [comment, setComment] = useState("");
  const [timeDiff, setTimeDiff] = useState(null);

  // const [emojiMap, setEmoji] = useState(post?.emojis)
  // const addEmoji = (e) => {
  //   setEmoji((prev) => ({ ...prev, [e]: [...(prev[e] || []), currentUser?.uid] }));
  //   logReaction(post, e)
  // };

  // const [updatePost, setUpdatePost] = useState({
  //   caption: post?.caption,
  //   imageURL: post?.imageURL,
  // });


  // const editPostHandler = () => {
  //   editPost(post, updatePost);
  //   setEnableEdit(false);
  // };

  // const handlePostImage = async (e) => {
  //   const file = e.target.files[0];
  //   const loader = toast.loading("uploading image");
  //   const path = `images/${currentUser.uid}/${file.name}`;
  //   const imageURL = await uploadImage(path, file);
  //   toast.success("uploaded image", { id: loader });
  //   setUpdatePost((prev) => ({
  //     ...prev,
  //     imageURL: imageURL,
  //   }));
  // };

  // const toggleLike = ({ likes, postID }) => {
  //   const userExists = likes?.some((user) => user.userID === currentUser?.uid);
  //   userExists
  //     ? dislikePost(postID, currentUser)
  //     : likePost(postID, currentUser);
  // };

  // const toggleArchive = (post) => {
  //   const isArchived = user?.archives?.some(
  //     ({ postID }) => postID === post?.postID
  //   );
  //   isArchived
  //     ? unarchivePost(post, currentUser)
  //     : archivePost(post, currentUser);
  // };

  // const toggleBookmark = (post) => {
  //   const isBookmarked = user?.bookmarks?.some(
  //     ({ postID }) => postID === post?.postID
  //   );
  //   isBookmarked
  //     ? undoBookmarkPost(post, currentUser)
  //     : bookmarkPost(post, currentUser);
  // };
  useEffect(() => {
    const postDate = new Date(post?.createdAt);
    const now = new Date();
    const diff = now.getTime() - postDate.getTime();
    const hoursDiff = Math.floor(diff / (1000 * 60 * 60));
    const daysDiff = Math.floor(hoursDiff / 24);
    const weeksDiff = Math.floor(daysDiff / 7);

    let timeString;
    if (weeksDiff > 0) {
      timeString = `${weeksDiff} week${weeksDiff > 1 ? 's' : ''} ago`;
    } else if (daysDiff > 0) {
      timeString = `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
    } else {
      if (hoursDiff === 0){
        timeString = "Just Posted!"
      }else if(!hoursDiff){timeString = "Long Ago LOL"}
      else{
        timeString = `${hoursDiff} hr${hoursDiff > 1 ? 's' : ''} ago`;

      }
    }

    setTimeDiff(timeString);
  }, [post.createdAt]);

  return (
    <div className="space-y-12 w-[87vw] md:w-[60vw] flex mx-auto border-gray-custom mt-4">
    <div className="flex flex-col md:mx-auto">
      <div className="flex flex-row">
      <Link to={`/profile/${post?.circleCreator}`} className="h-[35px] w-[35px]">
        <header className="flex items-center gap-2">
          <img
            src={post?.creatorPic ? post?.creatorPic : "https://firebasestorage.googleapis.com/v0/b/jooby-b9791.appspot.com/o/images%2Fundefined%2Fuser_default.png?alt=media&token=7df8b561-4914-4742-9bd6-0bccbf0e27af"}
            alt="user-dp"
            className="aspect-square h-[full w-full] rounded-full object-cover"
          />
        </header>
      </Link>
      <Link to={`/profile/${post?.circleCreator}`}>
      <p className="ml-2 mt-1 font-bold text-white">{`${post?.creatorName? post?.creatorName: "Anonymous"}`}</p>
      </Link>
      </div>
      <p className="text-gray-300 text-sm mb-2 mt-1">{`${timeDiff}`}</p>

      <div className="relative h-full feed_post overflow-hidden">
          {/* <p className="mx-autotext-base text-slate-50">{updatePost?.caption}</p> */}
          <div className="w-full h-[330px] rounded-lg">
          {post?.pic && (
            <img
              src={post?.pic}
              alt="post-one"
              className="mx-auto mt-2 picture_16_home w-full h-full object-cover rounded-t-lg"
            />
          )} 
          <div className="absolute left-4 bottom-10 text-white fontInter text-base z-[1]">{post?.circleName}</div>
          <div className="absolute left-4 bottom-2 z-[1]   h-[50px] w-full flex flex-row space-x-1 items-center">
                    <AccessTimeFilledIcon className="max-h-[15px] max-w-[15px] post_time z-[3"></AccessTimeFilledIcon>
                    <p className="post_time text-xs">{formatAMPM(new Date(post?.start), new Date(post?.end))}&nbsp; </p>
          </div>
          <div className="absolute top-4 flex right-2 flex-col rounded-md h-[41px] w-[41px] feed_date">
            <div className="flex flex-col  mx-auto my-auto">
            <div className="mx-1 text-xs w-full text-center text-white">{post?.date && (months[post?.date.toDate().getMonth()] )} &nbsp; </div>
            <p className="mx-1 text-xs text-white text-center w-full">{post?.date && (post?.date.toDate().getDate())} &nbsp; </p>
            </div>

                    </div>


              <Link to={`/circle/${post?.circleID}`} className="absolute bottom-8 right-2 bg-white w-[69px] h-[27px] hover:scale-105 text-black rounded-md font-semibold fontInter z-[1]">
                  <button className="h-full w-full fontInter font-bold">View</button>
              </Link>
          </div>
        </div>
        {/* Container for reactions */ } 
        <div className="h-[30px] w-full mt-2 mb-4 flex flex-row justify-around">
          {<button className="rounded-lg h-full discussion_bg w-[56px] text-md text-white bg-blue-200 hover:scale-105" onClick={() => logReactionEyes(post.circleID)}>👀 {post?.eyesEmoji}</button>}
          {<button className="rounded-lg h-full discussion_bg w-[56px] text-md text-white bg-blue-200 hover:scale-105" onClick={() => logReactionFire(post.circleID)}>🔥 {post?.fireEmoji}</button>}
          {<button className="rounded-lg h-full discussion_bg w-[56px] text-md text-white bg-blue-200 hover:scale-105" onClick={() => logReactionAlarm(post.circleID)}>🚨 {post?.alarmEmoji}</button>}


          {/* {(post?.emojis) && Array.from(Object.entries(post?.emojis)).map(([e,v]) => <button className="rounded-lg h-full w-[56px] bg-red-200 hover:scale-105"  onClick={() => addEmoji(e)}>{e}{v.length}</button>
)} */}
            {/* <div className="rounded-lg h-full w-[56px] bg-blue-200 hover:scale-105"></div>
            <div className="rounded-lg h-full w-[56px] bg-green-200 hover:scale-105"></div>
            <div className="rounded-lg h-full w-[56px] bg-yellow-200 hover:scale-105"></div> */}
      </div>

    </div>

      {/* {post.uid === currentUser?.uid && (
        <div className="absolute top-5 right-5 flex gap-4 text-gray-600">
          {enableEdit ? (
            <button
              className="rounded-full border-none bg-slate-100 p-1.5 text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
              onClick={() => editPostHandler(post, updatePost)}
            >
              <FiCheck />
            </button>
          ) : (
            <button
              className="rounded-full border-none bg-slate-100 p-1.5 text-l text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
              onClick={() => setEnableEdit((prev) => !prev)}
            >
              <FiEdit3 />
            </button>
          )}

          <button
            className="rounded-full border-none bg-slate-100 p-1.5 text-l text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
            onClick={() => deletePost(post)}
          >
            <FiTrash />
          </button>
          <button
            className="rounded-full border-none bg-slate-100 p-1.5 text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
            onClick={() => toggleArchive(post)}
          >
            {user?.archives?.some(({ postID }) => postID === post?.postID) ? (
              <FiBookmark className="text-l" />
            ) : (
              <FiBookmark className="text-l brightness-65" />
            )}
          </button>
        </div>
      )} */}

      <div className="flex items-center justify-between text-slate-50">
        <div className="flex items-center justify-start">
          <div className="flex items-center gap-2 text-lg">
            {/* {post?.likes?.find((user) => user.userID === currentUser?.uid) ? (
              <button
                className="rounded-full border-none bg-slate-100 p-1.5 text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
                onClick={() => toggleLike(post)}
              >
                <FaHeart className="text-red-700" />
              </button>
            ) : (
              <button
                className="rounded-full border-none bg-slate-100 p-1.5 text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
                onClick={() => toggleLike(post)}
              >
                <FiHeart />
              </button>
            )}
            {post?.likes?.length > 0 && post.likes.length} */}
          </div>
          {/* <div className="flex items-center gap-2">
            <button
              className="rounded-full border-none bg-slate-100 p-1.5 text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
              onClick={() => setEnableComments((prev) => !prev)}
            >
              <FaRegCommentAlt />
            </button>
            {post?.comments?.length > 0 && post.comments.length}
          </div> */}
        </div>
        {/* <button
          className="rounded-full border-none bg-slate-100 p-1.5 text-gray-500 shadow-md hover:cursor-pointer hover:brightness-95 "
          onClick={() => toggleBookmark(post, currentUser)}
        >
          {user?.bookmarks?.some(({ postID }) => postID === post?.postID) ? (
            <FaBookmark />
          ) : (
            <FaRegBookmark />
          )}
        </button> */}
      </div>

      {/* {enableComments && (
        <footer>
          <div className="relative w-full px-1">
            <textarea
              placeholder="Drop a comment.."
              className="mt-2 w-full resize-none bg-gray-100 px-2 py-2 text-sm focus:outline-none"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button
              className="absolute right-4 bottom-1/2 translate-y-1/2 rounded-md border-none bg-blue-500 px-3 py-1 text-sm text-gray-100"
              onClick={() => {
                postComment(post, comment, currentUser);
                setComment("");
              }}
            >
              Post
            </button>
          </div>
          {post?.comments?.map((comment) => (
            <Comment comment={comment} post={post} key={comment} />
          ))}
        </footer>
      )} */}
    </div>
  );
}
