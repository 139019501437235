import { auth } from "../firebase/firebase";
import { FiTrash } from "react-icons/fi";
import { deleteComment } from "../firebase/firebase-calls";
import { Link } from "react-router-dom";


export default function Comment({ comment, post }) {
  const currentUser = auth?.currentUser;
  const linkRegex = /((http|https):\/\/[^\s]+)/g;
  const commentWithLinks = comment.comment.replace(linkRegex, '<a class="text-blue-400 underline" href="$1">$1</a>');
  return (
    <div className="flex flex-col text-white rounded-md relative interFont w-full mt-8">
      <div className="flex-row flex w-full">
      <img
          src={comment?.avatar}
          alt=""
          className="mt-1 mr-2 h-[40px] w-[px] aspect-square rounded-full"
        />


        <div className="flex flex-col">
        <Link to={`/profile/${comment?.userID}`}>
        <p className="text-sm text-white font-extrabold min-w-[15vw] flex mt-1">{comment.displayName || "Anonymous"}</p>
        </Link>
        <p className="flex flex-col w-[250px] md:w-[80%] break-words	" dangerouslySetInnerHTML={{ __html: commentWithLinks }}/>
        </div>

        <p className="absolute right-8 text-gray-500 text-xs mt-1 mb-2">{comment.date}</p>
        
      </div>



      {comment.userID === currentUser.uid && (
        <button
          className="absolute right-5 top-4 text-sm"
          onClick={() => deleteComment(post, comment)}
        >
          <FiTrash />
        </button>
      )}
    </div>
  );
}
