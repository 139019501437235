import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import { auth } from "../../firebase/firebase";
import { useEffect, useState } from "react";
import { profileUpdate, uploadImage } from "../../firebase/firebase-calls";
import toast from "react-hot-toast";
import { FiEdit3 } from "react-icons/fi";

export default function EditProfileModal({
  setShowModal,
  userData,
  setUserData,
}) {
  const { user } = useSelector((state) => state.user);
  const currentUser = auth?.currentUser;

  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    coverPic: "",
    bio: "",
    name: "",
    Instagram: '',
  Snapchat: '',
  LinkedIn: '',
  Website: '',
  Discord: '',
  });

  const updateUserInfo = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  if (name === "Instagram" || name === "Snapchat" || name === "LinkedIn" || name === "Website" || name === "Discord") {
    // Check if the value starts with http:// or https://
    const pattern = /^(https?:\/\/)/;
    if (!pattern.test(value)) {
      // Add https:// prefix if it's missing
      newValue = `https://${value}`;
    }
  }
    setUserInfo((prev) => ({ ...prev, [name]: newValue }));
    console.log("changes")

  };

  const handleUserImage = async (e) => {
    const file = e.target.files[0];
    const field = e.target.name;
    const loader = toast.loading("uploading image");
    const path = `images/${currentUser.uid}/${file.name}`;
    const fieldURL = await uploadImage(path, file);
    toast.success("uploaded image", { id: loader });
    setUserInfo({ ...userInfo, [field]: fieldURL });
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    await setUserData((prev) => ({
      ...prev,
      coverPic: userInfo?.coverPic ? userInfo?.coverPic : userData.coverPic,
      bio: userInfo?.bio ? userInfo?.bio : userData.bio,
      name: userInfo?.name ? userInfo?.name : userData.name,
      Instagram: userInfo?.Instagram ? userInfo?.Instagram : userData.Instagram,
      Snapchat: userInfo?.Snapchat ? userInfo?.Snapchat : userData.Snapchat,
      LinkedIn: userInfo?.LinkedIn ? userInfo?.LinkedIn : userData.LinkedIn,
      Website: userInfo?.Website ? userInfo?.Website : userData.Website,
      Discord: userInfo?.Discord ? userInfo?.Discord : userData.Discord,




    }));
    setShowModal(false);
  };

  useEffect(() => {
    profileUpdate(userInfo, userData, currentUser, dispatch);
    // eslint-disable-next-line
  }, [userData]);

  return (
    <div className="fixed top-0 md:left-0 md:right-0 md:top-0 md:flex md:mx-auto md:w-[70vw] md:mt-1 z-30 max-h-screen h-full w-full mb-16 overflow-y-hidden md">
      <form
        className="flex h-full flex-col rounded-md blue_bg p-4 overflow-y-auto md:h-full md:w-full"
        onSubmit={updateUserData}
      >
        <AiFillCloseCircle
          className="absolute right-2 top-2 z-20 rounded-full bg-slate-100 text-3xl text-gray-500 hover:cursor-pointer  hover:brightness-90"
          onClick={() => setShowModal((prev) => !prev)}
        />
          <div className="left-4 text-white mt-4 cursor-pointer md:cursor-default relative aspect-[16/9] h-[120px] w-[120px] md:h-1/3 md:w-1/3 hover:scale-105">
          <label
            htmlFor="coverPic"
           
          >
            <input
              type="file"
              id="coverPic"
              name="coverPic"
              accept="image/*, .gif"
              className="hidden"
              onChange={handleUserImage}
            />          
            <img 
            src={userInfo?.coverPic ? userInfo?.coverPic : userData.coverPic}
            alt="No Photo Selected"
            className="object-cover h-full w-full cursor-pointer rounded-lg"
          />
          </label>
          Edit Cover Pic
          </div>

        { /* Used to be text area for bio */ }
        <div className="flex flex-col mt-12">
        <input
          type="text"
          name="bio"
          placeholder="Update Bio"
          className="mx-2 mt-4 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white eventRow"
          onChange={updateUserInfo}
        />
        <input
          type="text"
          name="name"
          placeholder="Update Name"
          className="mx-2 mt-4 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white eventRow"
          onChange={updateUserInfo}
        />
         <input
          type="text"
          name="Instagram"
          placeholder="Update Instagram"
          className="mx-2 mt-4 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white eventRow"
          onChange={updateUserInfo}
        />
        </div>


        { /* 
          <input
          type="text"
          name="Snapchat"
          placeholder="Snapchat"
          className="m-2 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white grey_bg"
          onChange={updateUserInfo}
        />
        <input
          type="text"
          name="LinkedIn"
          placeholder="LinkedIn"
          className="m-2 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white grey_bg"
          onChange={updateUserInfo}
        />
        <input
          type="text"
          name="Discord"
          placeholder="Discord"
          className="m-2 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white grey_bg"
          onChange={updateUserInfo}
        />
         <input
          type="text"
          name="Website"
          placeholder="Website"
          className="m-2 p-1 focus:outline focus:outline-1 focus:outline-slate-400 rounded-lg text-white grey_bg"
          onChange={updateUserInfo}
        />
        */ }

        {/* <select id="availablity" type="text" name="status" onChange={updateUserInfo} class="m-2 p-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full">
          <option selected>Specify your availability</option>
          <option value="Open to coffee chats">Open to coffee chats</option>
          <option value="Open to messaging">Open to messaging</option>
          <option value="Busy at the moment">Busy at the moment</option>
        </select> */}
        <button
          type="submit"
          className="mx-auto mt-8 w-fit rounded-lg bg-slate-100 px-4 py-2 text-sm outline-none hover:cursor-pointer hover:bg-slate-300 fontInter"
        >
          Update Profile
        </button>
      </form>
    </div>
  );
}
