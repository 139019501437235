import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import { joinCircle, getCircle, viewCount, textBlast,archiveCircle} from "../../firebase/firebase-calls";
//import EditProfileModal from "./EditProfileModal";
import {  Questions } from "components/components";
import { ResponseModal } from "components/ResponseModal"

import { Link } from "react-router-dom";
import SignUpPop from "./Signup";
import { format } from 'date-fns'
import { RWebShare } from "react-web-share";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import IosShareIcon from '@mui/icons-material/IosShare';
import GoogleCalendar from "components/GoogleCalendar";
import ICalendarLink from "react-icalendar-link";
import EditCircle from "./editCircle";
import { getAnalytics, logEvent } from "firebase/analytics";
import backgroundImage from "assets/BI2.jpg"
import confetti from 'canvas-confetti';


const options = { month: 'long', day: 'numeric' };
const timeOptions = { hour: 'numeric', minute: 'numeric'}

export default function Circles() {
  const { circleID } = useParams();
  const { user } = useSelector((state) => state.user);
  const { allPosts } = useSelector((state) => state.allPosts);
  const { allUsers } = useSelector((state) => state.allUsers);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const currentUser = auth?.currentUser;
  const [circleData, setCircleData] = useState([]);
  const authToken = localStorage.getItem("authToken");
  const [showSignUp, setShowSignUp] = useState(false)
  const [userData, setUserData] = useState([])
  const [choice, setChoice] = useState("")
  const website = `https://disko.rsvp/circle/${circleID} `
  const [showEdit, setShowEdit] = useState(false)
  const [views, setViews] = useState(0);
  const  [event, setEvent] = useState();

  useEffect(() => {
    if (showEdit) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
 }, [showEdit ]);


  const onClickConfetti = () => {
    confetti({
      particleCount: 150,
      spread: 60
    });
  };


  const analytics = getAnalytics();
  logEvent(analytics, 'event_views', { event_id: circleID });

  //get the one discussion for the circle
  const filteredPosts = allPosts?.filter(
    (post) => post.circle == circleID
  );

  //join the circle with the response if logged in
 const signUp = async(choice) =>{
    if (authToken){
      console.log("User Selected:" + choice)
      joinCircle (currentUser, circleData, choice)
      if (choice === "Yes") {
        onClickConfetti();
      }
    }else{
      //open the popup for signin 
      setChoice(choice)
      setShowSignUp((prev) => !prev)
    }
  }
  function formatAMPM(date1, date2) {
    var hours1 = date1.getHours();
    var hours2 = date2.getHours();
    var ampm1 = hours1 >= 12 ? 'pm' : 'am';
    hours1 = hours1 % 12;
    hours1 = hours1 ? hours1 : 12; // the hour '0' should be '12'
  
    console.log(hours2);
    var ampm2 = hours2 >= 12 ? 'pm' : 'am';
    hours2 = hours2 % 12;
    hours2 = hours2 ? hours2 : 12; // the hour '0' should be '12'
  
    var strTime = hours1 + ' ' + ampm1 + ' - ' + hours2 + ' ' + ampm2;
    return strTime;
  }
 

  useEffect(() => {
    if (circleData?.length == 0) {
      getCircle(circleID, setCircleData);
      

      setTimeout(() => {
        viewCount(circleID);
        setIsLoading(false);
      }, 750); // wait for 0.75 second before calling getCircle
    }
  }, [circleData]);

  const archive = async(e) =>{
    archiveCircle(`${circleID}`);
    navigate("/");
  }



  return (

    <div className="w-full" >
      <div className="fixed inset-0 z-0">
          <img src={backgroundImage} alt="Background" className="object-cover w-full h-full" />
      </div>
      { /*<QuestionTextEditor CircleID={circleData?.circleID}/> */}
      {isLoading && <p>Loading</p>}

      {!isLoading && (
        
      <div className="h-full w-full relative z-10" >
       <section className="mx-auto md:w-[630px]">
        <div class="h-full w-full">
         {circleData.Pic && (
           <img
             src={circleData.Pic}
             alt="EventPic"
             className="mx-auto h-[380px] w-full px-8 object-cover bg-gradient-to-b from-slate-50 to-neutral-900 aspect-[16/9]"
           />
           
         )}
 {/* remove blue bg */}
         <div className="flex h-fit flex-col w-full items-center gap-2  text-slate-50">
          <div className="relative md:mx-8 md:flex md:w-[630px] md:mt-32 md:overflow-hidden">
            <img
              src={circleData?.pic}
              alt="event-dp"
              className="h-[340px] w-full px-8 md:px-0 md:mx-8 md:max-w-[630px] md:h-[500px] md:flex object-cover md:object-cover picture_16_event overflow-hidden mt-8"
            />
              <div className="absolute bottom-3 right-8 md:right-8 z-[1] p-2 rounded-lg">
              <RWebShare
                data={{
                  text: "RSVP for "+ circleData.circleName,
                  url: website,
                  title: "Disko",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div className="w-full bg-white text-neutral-800 rounded-full z-[1]">
                <button className="h-[38px] w-[38px]"><IosShareIcon></IosShareIcon></button>
                </div>
              </RWebShare>
              </div>

                  
          </div>
         

           <div class="mx-8 text-start text-slate-50">
           <p className="text-2xl w-full font-extrabold text-slate-50">{circleData?.circleName}</p>
           <div class="flex flex-row text-xs my-1">
           <p className="text-slate-50"><AccessTimeTwoToneIcon></AccessTimeTwoToneIcon>&nbsp;{new Date(circleData?.date?.seconds*1000).toLocaleString("en-US", options)} from {formatAMPM(new Date(circleData?.start), new Date(circleData?.end))}&nbsp;&nbsp;&nbsp;&nbsp;
           </p>
           <p className="text-slate-50"><PlaceTwoToneIcon></PlaceTwoToneIcon>&nbsp;&nbsp;{(circleData?.circleCosts == "" ? "Sorry no location 😖": circleData?.circleCosts)}</p>
           
           </div>

           <div className="w-full">
           
           {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
           <ICalendarLink event={{
      
      title: `${circleData?.circleName}`,
      description: `${circleData?.circleDescription}`,
      startTime: new Date(circleData?.date?.toDate()),
      endTime: new Date(circleData?.date?.toDate()),
      location: circleData?.circleCosts,
    }}>
      *Add to Google Cal
    </ICalendarLink>
           </button>
           *Doesn't work on Safari. Apple Lame. */}
           </div>
          <div className="space-x-2">
            
          </div>
          {(circleData?.circleCreator === currentUser?.uid) && ( 
            <div className="space-x-2 mt-2 mb-4">
            <button className="px-4 py-2 purple_bg rounded-lg text-black fontInter text-shadow hover:scale-105"             onClick={() => setShowEdit((prev) => !prev)}>Edit Event</button >
            <button className="px-4 py-2 bg-yellow-300 rounded-lg text-black text-shadow hover:scale-105" onClick={() => textBlast(circleData)}>Reminder</button>
            <button className="px-4 py-2 bg-red-300 rounded-lg text-black text-shadow hover:scale-105" onClick={() => archive()}>Archive</button>
            
            </div>)
            
      
          }
           <p className="text-start w-full font-extrabold mt-4 mb-1">About </p>
           <p className="text-start w-full text-sm">{(circleData?.circleDescription == "" ? "We swear they have some awesome event details brewing back here 😆 Come back in a bit for a pleasant surprise!": circleData?.circleDescription)}</p>

           <div className="flex flex-row w-full justify-between mt-4">
            <div className="self-center">
            <p className="text-left font-extrabold flex">Host</p>
            
            <Link to={`/profile/${circleData?.circleCreator}`} class="flex items-center">
                  <img
                src={allUsers.find(user => user?.uid === circleData?.circleCreator)?.coverPic}
                alt=""
                className="h-7 aspect-square rounded-full"
              />
                <p className="text-sm"> &nbsp; {allUsers.find(user => user?.uid === circleData?.circleCreator)?.name}</p>
            </Link>
            </div>
            <ResponseModal responses={circleData?.memberCount?.length} users={allUsers} event={circleData}/>
            { /* <p className="text-left font-extrabold">{circleData?.memberCount?.length} Responses </p> */ }

           </div> 

           <p className="text-left text-lg mt-2 self-center font-extrabold">{circleData?.views} Views </p>


          { /* 
           <p className="text-left font-extrabold mt-4">Who's going?</p>
          <ul className="mx-auto flex flex-col text-center items-center font-light">
          {circleData?.memberCount?.map((member) => ( 
            <div className="rounded-lg pt-1 font-semibold text-left w-full flex flex-row text-white">
            <Link to={`/profile/${member?.uid}`} class="flex items-center">
            <img
                src={allUsers.find(user => user?.uid === member?.uid)?.coverPic}
                alt=""
                className="h-7 aspect-square rounded-full"
              />
                          <p className="text-left font-extrabold"> &nbsp;{allUsers.find(user => user?.uid === member?.uid)?.name} &nbsp;is a {member?.response}</p>
          </Link>
            </div>
            ))}

          </ul>

          */ }

           <div class="flex flex-row w-full mx-auto my-4 content-center">
            <button
              className="h-14 w-14 fixed bottom-20 left-16 md:left-1/3 z-[1] mx-auto border-solid border-yellow-400 bg-yellow-200 bg-opacity-20 border rounded-full text-4xl hover:cursor-pointer hover:brightness-95"
              onClick = {() => signUp("No")  } //Follow Button for Circles
                
            >
            &#128078;             
            </button>
            <button
              className="h-14 w-14 fixed bottom-20 left-0 z-[1] right-0 mx-auto border-solid border-green-200 bg-green-200 bg-opacity-20 border rounded-full text-4xl hover:cursor-pointer hover:brightness-95"
              onClick = {() => signUp("Yes") } //Follow Button for Circles
            >
            &#128077;          
            </button>
            <button
              className="h-14 w-14 fixed bottom-20 right-16 md:right-1/3 z-[1] mx-auto border-solid border-purple-300 bg-purple-300 bg-opacity-20 border rounded-full text-4xl hover:cursor-pointer hover:brightness-95"
              onClick = {() => signUp("Maybe")  } //Follow Button for Circles
                
            >
            &#129300;	         
            </button>
          </div>
        </div>
         </div> 
        </div>

        <ul className="text-white mb-44 h-full md:flex md:mx-auto">
               
               {(authToken)? filteredPosts?.map((post) => ((post.type == "QA") && <Questions post = {post} key={post.postID} currentCircle={circleData}/>)):
                (<div className="ml-8 fontInter text-white w-fit flex flex-row"><a className="w-full text-left underline" href="/">Login to view Discussion</a>
                 </div>)}
               
        </ul>
       </section>


         </div>
      )
      }
       {showSignUp && (
        <div className="h-full w-full">
          <div className="fixed flex h-1/2 w-80 items-center justify-center opacity-70 "></div>
          <SignUpPop
            setShowModal={setShowSignUp}
            userData={userData}
            setUserData={setUserData}
            key={userData?.userID}
            choice={choice}
            circleData={circleData}
            joining={true}
          />
        </div>
      )}
      {showEdit && (
        <div className="h-full">
          <div className="fixed inset-0 flex h-screen w-full items-center justify-center bg-black opacity-70 "></div>
          <EditCircle
            setShowModal={setShowEdit}
            setCircleData={setCircleData}
            key={circleData?.circleID}
            circleData={circleData}
          />
        </div>
      )}
    </div>
  );
}