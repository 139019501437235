// import { TextEditor, Post, RightSideBar } from "components/components";
// import { Empty } from "pages/pages";
// import { useState } from "react";
// import { useSelector } from "react-redux";
// import MenuIcon from '@mui/icons-material/Menu';

// import disko_logo from "assets/disko_logo.png";


// export default function Home() {
//   // const { user } = useSelector((state) => state.user);
//   const { allPosts } = useSelector((state) => state.allPosts);
//   const {allFeed} = useSelector((state) => state.allFeed)
//   const { allCircles } = useSelector((state) => state.allCircles);
  
//   const [sortBy, setSortBy] = useState("Latest");
//   // const followingUsers = user?.following;
  

//   const posts = [...allCircles].sort(
//     (a, b) => {
//   const aDate = new Date(a?.date);
//   const bDate = new Date(b?.date);
//   const today = new Date();

//   const aDiff = Math.abs(aDate - today);
//   const bDiff = Math.abs(bDate - today);

//   return aDiff - bDiff;
//     }
//   );

//   return (
//     <div className="flex w-full md:w-[70vw] flex-col blue_bg mx-auto">
//       <div className="flex flex-row">
       
//       </div>

//       <img src={disko_logo} alt="logo" className="h-[75px] w-[75px] mt-2 md:mt-0 flex mx-auto"></img>
//       <div className="mt-2 flex flex-row space-x-2">
        
      
//       </div>
//       <div className="mt-2 mb-24">
//         {posts?.length > 0 ? (
//           posts?.map((post) => <Post post={post} key={post.postID} />)
//         ) : (
//           <div className="flex h-1/2 items-center pt-4 text-white">
//             <Empty />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }
import { TextEditor, Post, RightSideBar } from "components/components";
import { Empty } from "pages/pages";
import { useState } from "react";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';

import disko_logo from "assets/disko_text_logo.png";

export default function Home() {
  const { allCircles } = useSelector((state) => state.allCircles);
  const [sortBy, setSortBy] = useState("Latest");

  const currentDate = new Date();
  const today = new Date(currentDate.setDate(currentDate.getDate() -1));


  const upcomingCircles = [];
  const pastCircles = [];

  allCircles.forEach((circle) => {
    const circleDate = new Date(circle?.date?.seconds*1000);
    if (circleDate >= today && (circle?.archive != true)) {
      upcomingCircles.push(circle);
    } else if((circle?.archive != true)){
      pastCircles.push(circle);
    }
  });

  const sortedUpcomingCircles = [...upcomingCircles].sort(
    (a, b) => new Date(a?.date?.seconds*1000) - new Date(b?.date?.seconds*1000)
  );

  const sortedPastCircles = [...pastCircles].sort(
    (a, b) => {
      const aEyes = a?.eyesEmoji ?? 0;
      const bEyes = b?.eyesEmoji ?? 0;
      const aFire = a?.fireEmoji ?? 0;
      const bFire = b?.fireEmoji ?? 0;
      const aAlarm = a?.alarmEmoji ?? 0;
      const bAlarm = b?.alarmEmoji ?? 0;
      return ((b?.memberCount?.length*2)+bEyes + bFire + bAlarm) - ((a?.memberCount?.length*2)+aEyes + aFire + aAlarm);
    }
  );

  return (
    <div className="flex w-full md:w-[70vw] flex-col blue_bg mx-auto">
      <div className="flex flex-row">
      </div>

      <img src={disko_logo} alt="logo" className="aspect-[16/9] w-[90px] h-[32px] mt-12 md:mt-0 flex mx-auto"></img>
      <div className="mt-2 flex flex-row space-x-2">
      </div>

      <div className="mt-2 mb-24">
        {upcomingCircles.length > 0 && (
          <>
            <h2 className="bg-clip-text bg-gradient-to-r from-pink-700 to-purple-200 text-lg font-bold mb-2 md:w-full text-transparent text-center">Upcoming Events</h2>
            {sortedUpcomingCircles.map((circle) => (
              <Post post={circle} key={circle.postID} />
            ))}
          </>
        )}

        {sortedPastCircles.length > 0 && (
          <>
            <h2 className="bg-clip-text bg-gradient-to-r from-purple-200 to-pink-700 text-lg font-bold mb-2 md:w-full text-transparent text-center mt-8">Past Popular Events</h2>
            {sortedPastCircles.map((circle) => (
              <Post post={circle} key={circle.postID} />
            ))}
          </>
        )}

        {upcomingCircles.length === 0 && pastCircles.length === 0 && (
          <div className="flex h-1/2 items-center pt-4 text-white">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
}
