
import ApiCalendar from 'react-google-calendar-api';

const config = {
    "clientId": process.env.REACT_APP_CALENDAR_CLIENT_ID,
    "apiKey": process.env.REACT_APP_CALENDAR_API_KEY,
    "scope": "https://www.googleapis.com/auth/calendar",
    "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
}

const apiCalendar = new ApiCalendar(config)



export default function GoogleCalendar({circleData}) {
  if (circleData?.length !=0){
    console.log(circleData?.date);
    var gapi = window.gapi

    var event = {
        summary: `${circleData?.circleName}`,
        location: `${circleData?.circleBio}`,
        description: `${circleData?.circleDescription}`,
        start: {
          // dateTime: new Date(circleData?.date.toDate()),
          'dateTime': '2023-02-12T17:00:00-07:00',

          timeZone: "America/Los_Angeles",
        },
        end: {
          // dateTime: new Date(circleData?.date.toDate()),
          'dateTime': '2023-02-12T17:00:00-07:00',

          timeZone: "America/Los_Angeles",
        },
        recurrence: [],
        attendees: [],
        reminders: {
          useDefault: false,
          overrides: [
            { method: "email", minutes: 24 * 60 },
            { method: "popup", minutes: 30 },
          ],
        },
      };

    
  }
    
  const addEvent = (e) => {
    apiCalendar.handleAuthClick()


    const request = gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': event
      });
      
      request.execute(function(event) {
        console.log(event)
        window.open(event.htmlLink)
      });
    
} 
    


    
    return(
        <button
            onClick={(e) => addEvent(e)}>
                Add to Google Cal
        </button>
    );

}