
import Widget from "components/widget/Widget";
import { MdBarChart, MdDashboard } from "react-icons/md";
import { IoDocuments } from "react-icons/io5";
import { IoMdHome } from "react-icons/io";
import DailyTraffic from "../../views/admin/default/components/DailyTraffic";
import PieChartCard from "../../views/admin/default/components/PieChartCard";

//imports for Events on bottom
import { Link } from "react-router-dom";
import { AiOutlineRight } from 'react-icons/ai';
import { useSelector } from "react-redux";
import { getUser } from "../../firebase/firebase-calls";
import { useEffect, useState } from "react";



//Logic Data Points

import { auth } from "../../firebase/firebase";


export default function Dashboard({


}) {
    const options = { month: 'long', day: 'numeric' , hour: 'numeric', minute: 'numeric'};
    const currentUser = auth?.currentUser
    const {allCircles} = useSelector((State) => State.allCircles);
    const [userData,setUserData] = useState([])

    var eventViews = 0;
    var responses = 0;
    var yes = 0;
    var no = 0;
    var maybe = 0;
    var allResponses = []

    const filteredCircles = allCircles?.filter(
        (circle) => circle.circleCreator === currentUser?.uid
    );
    
    filteredCircles.map((circle)=>{
        eventViews+=circle.views;
        responses+=circle.memberCount.length;
        allResponses.push(circle.memberCount)
       
    })

    allResponses = allResponses.flatMap(array => array)
    allResponses.forEach((response) => {
        const choice = response.response;
        if (choice === "Yes") {
          yes += 1;
        } else if (choice === "No") {
          no += 1;
        } else if (choice === "Maybe") {
          maybe += 1;
        }
      });

    const { maxCircle, maxRatio } = filteredCircles.reduce((acc, circle) => {
        const ratio = circle.memberCount.length / circle.views;
        if (ratio > acc.maxRatio) {
          return { maxCircle: circle?.circleName, maxRatio: ratio };
        } else {
          return acc;
        }
      }, { maxCircle: null, maxRatio: 0 });
      useEffect(
        () => {
          if (userData.length == 0) {
            getUser(currentUser, setUserData);
            
          }
        },
        // eslint-disable-next-line
        [currentUser]
      );
    
console.log(eventViews)




    return(
        <div class="pt-5 h-full min-h-[84vh] w-[70vw] mx-auto text-black">
 <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
    <Widget
      icon={<MdBarChart className="h-7 w-7" />}
      title={"Profile Views"}
      subtitle={userData?.views ? userData.views : 0}
    />
    <Widget
      icon={<IoDocuments className="h-6 w-6" />}
      title={"Event Views"}
      subtitle={eventViews}
    />
    <Widget
      icon={<MdBarChart className="h-7 w-7" />}
      title={"Event Responses"}
      subtitle={responses}
    />
    <Widget
      icon={<MdDashboard className="h-6 w-6" />}
      title={"CTR (Click Through Rate)"}
      subtitle={`${(responses/eventViews * 100).toFixed(2)}%`}
    />
    <Widget
      icon={<MdBarChart className="h-7 w-7" />}
      title={"Number of Events"}
      subtitle={filteredCircles?.length}
    />
    <Widget
      icon={<IoMdHome className="h-6 w-6" />}
      title={"Best CTR Event"}
      subtitle={`${maxCircle} @ ${(maxRatio * 100).toFixed(2)}% CTR`}
    />
     

  </div>
  <div className="mt-3 grid mx-0 mb-20 grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2 w-full">
          {/* <DailyTraffic /> */}
          <PieChartCard pieChartData={[yes,no,maybe]}/>
        </div>
  {/* {filteredCircles?.map((circles) => (
         <Link className="button rounded-lg"
         to={`/circle/${circles?.circleID}`}
         >
           <div className="mx-6 sm:text-sm eventRow rounded-lg flex flex-row h-[57px]">
             <div className="">
               <img src={circles.pic} 
               alt="" 
               className = "blue_bg h-[57px] min-w-[57px] max-w-[57px] object-cover hover:opacity-70 rounded-lg"/>
             </div>

             <div className="ml-4 mt-2 w-full">
               <h5 className="font-bold text-left mx-auto">
                 {circles.circleName}
               </h5>
               <p className="text-gray-400 text-xs">{new Date(circles?.date?.seconds*1000).toLocaleString("en-US", options)} &nbsp; </p>
             </div>
             <div className="my-auto w-fit">
             <AiOutlineRight className="h-[40px] w-[25px] my-auto text-gray-400"></AiOutlineRight>
             </div>
           </div>
       </Link>
       ))} */}
        </div>
   
)
  
}