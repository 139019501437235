import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';

import { auth } from "../../firebase/firebase";
import { getUser, addSocials} from "../../firebase/firebase-calls";
import EditProfileModal from "./EditProfileModal";
import CreateEventModal from "./CreateEventModal";
import { MdOutlineLogout } from "react-icons/md";
import {useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { logout } from "features/user/userSlice";

import { SocialIcon } from "react-social-icons";
import { RWebShare } from "react-web-share";
import IosShareIcon from '@mui/icons-material/IosShare';
import ToggleEventModal from "components/ToggleEventModal";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import admin_png from "assets/admin.png";
import InstagramAuth from "components/instagramAPILogin";


const options = { month: 'long', day: 'numeric' , hour: 'numeric', minute: 'numeric'};



export default function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentDate = new Date();
  const todayDate = new Date(currentDate.setDate(currentDate.getDate() -1));

  const authToken = localStorage.getItem("authToken");
  if (!authToken) {navigate("/login")}

  const { user } = useSelector((state) => state.user);
  const { allPosts } = useSelector((state) => state.allPosts);
  const {allCircles} = useSelector((State) => State.allCircles);
  const [website, setWebsite] = useState("");
  



  //profile changes
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [social, setSocialData] = useState("")

  const [showModalExp, setShowModalExp] = useState(false);
  const [showSocial, setShowSocial] = useState(false);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
 }, [showModal ]);


  const currentUser = auth?.currentUser;

  
  ///phone number authentication
  //check if auth token exists
  const joinedCircles = userData?.joinedCircle?.map(({ uid }) => uid);


  const filteredCircles = allCircles?.filter(
    (circle) => circle.circleCreator === currentUser?.uid && (circle?.archive != true)
  );

  const subscribedCircles = allCircles?.filter(
    (circle) => userData?.following?.includes(circle.circleCreator) & new Date(circle?.date?.seconds*1000) >= todayDate
  );



  const joinedCircle = allCircles?.filter(
    (circle) => joinedCircles?.includes(circle.circleID)
  ); 



  
  const upcoming = joinedCircle?.filter(
    (circle) => (new Date(circle?.date?.seconds*1000) >= todayDate) && (circle?.archive != true)
  ); 
  const past = joinedCircle?.filter(
    (circle) => new Date(circle?.date?.seconds*1000) < todayDate && (circle?.archive != true)
  ); 
  

const logoutHandler = async () => {
  dispatch(logout());
  await signOut(auth);
  navigate("/login");
  localStorage.removeItem("authToken");
};
const createCircle = async (e) => {
      e.preventDefault();
      createCircle()

};

const addSocial = async ()=>{
    addSocials(currentUser, "https://" + social)
    setShowSocial(false);
}


  useEffect(
    () => {
      if (userData.length == 0) {
        getUser(currentUser, setUserData);
        setWebsite(`https://disko.rsvp/profile/${currentUser?.uid}`)
      
      }
      console.log("User Fetched!")
    },
    // eslint-disable-next-line
    [currentUser]
  );

  return (
    <div className="blue_bg w-full overflow-hidden">
      <div className=" h-full">
      <section className="relative w-full h-full md:w-[630px] flex mx-auto blue_bg">
          <div className="relative md:block mb-8 md:mb-24 w-full mx-auto user_bg md:mt-24 glow rounded-md">

          <img
            src={userData?.coverPic ? userData.coverPic : "https://firebasestorage.googleapis.com/v0/b/jooby-b9791.appspot.com/o/images%2Fundefined%2Fuser_default.png?alt=media&token=7df8b561-4914-4742-9bd6-0bccbf0e27af"}
            alt="cover"
            className="absolute flex left-6 top-16 mx-auto h-[66px] w-[66px] object-cover rounded-full md:mt-8 md:rounded-full "
          />
          <button onClick={logoutHandler}
            className="flex absolute left-4 top-1 border-none p-2 text-2xl text-white hover:cursor-pointer hover:brightness-95 my-auto">
            <MdOutlineLogout className="min-h-[32px] min-w-[32px]" />
          </button>
            <div className="absolute flex left-20 top-20">
            <p className="z-[1] text-2xl ml-6 text-white font-bold fontInter">{userData?.name}</p>
            </div>
            <div className="flex absolute bottom-[88%] right-8 rounded-lg z-[1] space-x-4">
              <RWebShare
                data={{
                  text: "RSVP for ",
                  url: website,
                  title: "Disko",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div className="bg-white relative right-0 bottom-0 text-neutral-800 rounded-full">
                <button className="h-[38px] w-[38px]"><IosShareIcon></IosShareIcon></button>
                </div>
              </RWebShare>

          </div>

          <div className="absolute left-20 top-28 mx-auto flex text-white text-sm flex-col items-center gap-2 md:w-[630px]">
          {/* <img
            src={user?.photoURL}
            alt="user-dp"
            className="md:h-18 lg:h-18 aspect-square h-14 w-fit rounded-full object-cover xl:h-24"
          /> */}

        
          <p className="flex w-full ml-12">{userData?.bio}</p>
          <p className="text-sm sm:text-base">{userData?.website}</p>
          {/* <InstagramAuth /> */}


          { /* <p className="text-sm sm:text-base">Status: {userData?.status}</p> */ }

          <div className="flex flex-row text-white ml-12 w-full space-x-4">
            <button
                className=" h-[26px] w-[100px] rounded-md text-white eventRow fontInter text-shadow hover:scale-105"
                onClick={() => setShowModal((prev) => !prev)}>Edit Profile
                </button> 
            <button
                className=" h-[26px] w-[100px] rounded-md text-white flex flex-row items-center eventRow fontInter text-shadow hover:scale-105"
                >
                  <a className="h-full w-full flex items-center" href="/admin">
                    <div className="flex mx-auto">
                      <img className="h-full" src={admin_png}></img>
                      <h5 className="ml-1">Analytics</h5> 
                    </div>
                  </a>
            </button> 
            {/* <div className="flex flex-row gap-1 text-slate-400">
              <p className="font-extrabold text-white"> {userData?.followers?.length > 0 ? userData?.followers?.length : "0"}</p>
              Followers
            </div>
            <div className="flex flex-row gap-1 text-slate-400">
             <p className="font-extrabold text-white"> {userData?.following?.length > 0 ? userData?.following?.length : "0"}</p>
                Following
            </div> */}
          </div>
        </div>

        <div className="absolute top-48 md:w-[630px] md:h-[20vh] mx-auto blue_bg">
        <div className="h-full w-[100vw] md:w-[630px]">
  <ToggleEventModal upcoming={upcoming} past={past} my_events={filteredCircles} ></ToggleEventModal>
  </div>

  {showModalExp && (
  <div className="h-full">
    <div className="fixed inset-0 flex h-screen w-full items-center justify-center bg-gray-900 opacity-70 "></div>
    <CreateEventModal
      setShowModal={setShowModalExp}
      userData={userData}
      setUserData={setUserData}
      key={userData?.userID}
    />
  </div>
  )}
  {showModal && (
  <div className="h-full">
    <div className="inset-0 flex h-screen w-full ml-64 items-center justify-center"></div>
    <EditProfileModal
      setShowModal={setShowModal}
      userData={userData}
      setUserData={setUserData}
      key={userData?.userID}
    />
  </div>
  )}


{/* <button onclick="buttonHandler()" title="Contact Sale"
class="fixed z-90 bottom-10 right-8 bg-blue-600 w-20 h-20 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300">&#9993;</button> */}
  </div>
</div>


</section>

{ /* Area for social icons placeholder */ }
{/* 
    <div className="flex flex-row mt-4 md:w-[630px] md:mx-auto">
    {userData?.Instagram || userData?.Snapchat || userData?.LinkedIn || userData?.Website || userData?.Discord ? (<div></div>) : (<div className="text-white ml-6 text-sm">Add socials using edit!</div>)}

      {userData?.Instagram && userData?.Instagram?.length > 0 && (<div class="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Instagram} />
        </div>)}
      {userData?.Snapchat && userData?.Snapchat?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Snapchat} />
      </div>
      )}

      {userData?.LinkedIn && userData?.LinkedIn?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.LinkedIn} />
      </div>
      )}

      {userData?.Website && userData?.Website?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Website} />
      </div>)}
      {userData?.Discord && userData?.Discord?.length > 0 && (
      <div className="ml-6 flex h-[40px] w-[40px] rounded-full bg-white">
      <SocialIcon className="object-cover mx-auto" style={{ height: 40, width: 40 }} url={userData?.Discord} />
      </div>)}
    </div>
      */ }
</div>
{/*     
    <ul className="mt-5 mb-16 md:mb-24">
       
        {userData?.socials?.map((links) => (
           <SocialIcon url = {links}/>
        ))}

        {showSocial && <div><input type="text" placeholder={"Social Link"}
                onChange={(e) => { setSocialData(e.target.value)}}></input>
                <button
                className="absolute right-4 rounded-full border-none bg-slate-100 p-1.5 text-2xl text-gray-600 shadow-md hover:cursor-pointer hover:brightness-95 "
                onClick={addSocial}
              >
                <FiEdit2 />
          </button></div>}

       {(!showSocial) && <button
            className="absolute right-4 rounded-full border-none bg-slate-100 p-1.5 text-2xl text-gray-600 shadow-md hover:cursor-pointer hover:brightness-95 "
            onClick={() => setShowSocial((prev) => !prev)}
          >
            <FiEdit3 />
      </button>}
</ul> */}

    </div>
  );
}
