import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import { auth } from "../../firebase/firebase";
import { useEffect, useState } from "react";
import { editCircle, profileUpdate, uploadImage } from "../../firebase/firebase-calls";
import toast from "react-hot-toast";
import { FiEdit3 } from "react-icons/fi";
import { snapshotEqual } from "firebase/firestore";
import calendar from "assets/calendar.png";
import Calendar from '@hassanmojab/react-modern-calendar-datepicker';
import TimePicker from 'rc-time-picker';



export default function EditCircle({
  setShowModal,
  setCircleData,
  circleData
}) {
  const { user } = useSelector((state) => state.user);
  const currentUser = auth?.currentUser;

  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    circleDescription: "",
    circleName: "",
    pic: "",
    circleCosts:"",
    coverPic: "https://firebasestorage.googleapis.com/v0/b/jooby-b9791.appspot.com/o/images%2Fundefined%2Fevent_default.png?alt=media&token=1b1c2566-9869-490a-a464-c58d050352ed",
    date: "",
  });


  const updateEventNameInfo = (e) => {
    const { name, value } = e.target;
    const num_value = e.target.value;

    if (num_value.length <= maxLength) {
      setInputValue(num_value);
      setUserInfo((prev) => ({ ...prev, [name]: value }));
    }
  };
  
  const updateUserInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    console.log("changes")
  };

  const handleUserImage = async (e) => {
    const file = e.target.files[0];
    const field = e.target.name;
    const loader = toast.loading("uploading image");
    const path = `images/${currentUser.uid}/${file.name}`;
    const fieldURL = await uploadImage(path, file);
    toast.success("uploaded image", { id: loader });
    setUserInfo({ ...userInfo, [field]: fieldURL });
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    await setCircleData((prev) => ({
      ...prev,
      pic: userInfo?.pic ? userInfo?.pic : circleData.pic,
      circleDescription: userInfo?.circleDescription ? userInfo?.circleDescription : circleData.circleDescription,
      circleName: userInfo?.circleName ? userInfo?.circleName : circleData.circleName,
      circleCosts: userInfo?.circleCosts ? userInfo?.circleCosts : circleData.circleCosts,


    }));
    setShowModal(false);
  };

  useEffect(() => {
    editCircle(userInfo, circleData, startTime, endTime, selectedDay, dispatch);
    // eslint-disable-next-line
  }, [circleData]);

  const input = document.getElementById('create_input');
  const [inputValue, setInputValue] = useState('');
  const maxLength = 20;
  const [selectedDay, setSelectedDay] = useState(null);
  const [startTime, setStart] = useState();
  const [endTime, setEnd] = useState();

  const renderCustomInput = ({ ref }) => (
    <div className="eventRow rounded-lg flex mt-2 mx-4 p-2 w-[84vw] md:w-[560px] focus:outline text-white focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-4">
      <img src={calendar} className="w-[15px] h-[17px] mr-4 my-auto flex"></img>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="Update Date"
        value={selectedDay ? `${selectedDay.month}-${selectedDay.day}-${selectedDay.year}` : ''}
        className="w-full"
      />
    </div>
    )
    



  return (
    <div className="fixed top-0 left-0 right-0 z-30 h-full w-full md:left-0 md:right-0 md:w-[70vw] md:flex md:mx-auto md:mt-4 overflow-y-auto">
      <form
        className="flex h-full w-full flex-col rounded-md blue_bg p-4 overflow-y-auto"
        onSubmit={updateUserData}
      >
        <AiFillCloseCircle
          className="absolute right-2 top-2 z-20 rounded-full bg-slate-100 text-3xl text-gray-500 hover:cursor-pointer  hover:brightness-90"
          onClick={() => setShowModal((prev) => !prev)}
        />
          <div className="left-4 text-white mt-4 mb-12 cursor-pointer md:cursor-default relative aspect-[16/9] h-[120px] w-[120px] md:h-1/3 md:w-1/3 hover:scale-105">
          <label
            htmlFor="pic"
           
          >
            <input
              type="file"
              id="pic"
              name="pic"
              accept="image/*, .gif"
              className="hidden"
              onChange={handleUserImage}
            />          
            <img 
            src={userInfo?.pic ? userInfo?.pic : circleData?.pic}
            alt="updated-dp"
            className="object-cover h-full w-full cursor-pointer rounded-lg"
          />
          </label>
          Edit Cover Pic
          </div>


        <div className="flex mx-4 mt-2 justify-end text-gray-400"> {inputValue.length === maxLength && (
        <div className="warning-message">Character limit reached</div>
      )}</div>
        <div className="eventRow rounded-lg flex my-4 mx-4 mt-4 p-2 focus:outline text-white focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-4">
        <input
          id="create_input"
          type="text"
          name="circleName"
          placeholder="Update Event Name"
          onChange={updateEventNameInfo}
          value={inputValue}
          className="w-full"
          maxlength="20"
        />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '0', height: '150px', width: '100%'}}>
            <Calendar
              value={selectedDay}
              onChange={setSelectedDay}
              renderInput={renderCustomInput} // render a custom input
              shouldHighlightWeekends
              colorPrimary="#9c88ff" // added this
              />
          </div>


          <div className="flex flex-row mx-4 justify-between text-white">
            <div className="relative flex flex-col">
              <h3 className="relative left-4">Start Time</h3>
            <TimePicker
              showSecond={false}
              className="w-[133px] h-[44px]"
              onChange={(date) => {
                setStart(date);
                console.log("Here")
                console.log(startTime);
              }}
              // hideDisabledOptions
              minuteStep={15}
              // {...others}
              value={startTime}
              use12Hours
            />
            </div>

            <div className="flex flex-col relative">
              <h3 className="relative left-4">End time</h3>
              <TimePicker
                showSecond={false}
                className="w-[133px] h-[44px] "
                onChange={(date) => {
                  setEnd(date);
                }}
                // hideDisabledOptions
                minuteStep={15}
                // {...others}

                value={endTime}
                use12Hours
              />
            </div>
          </div>

        <div className="eventRow rounded-lg flex my-4 mx-4 mt-4 p-2 focus:outline text-white focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-4">
        <input
          type="text"
          name="circleDescription"
          placeholder="Update Description"
          className="w-full"
          onChange={updateUserInfo}
        />
        </div>

        
        <div className="eventRow rounded-lg flex my-4 mx-4 mt-4 p-2 focus:outline text-white focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-4">
        <input
          type="text"
          name="circleCosts"
          placeholder="Update Location"
          className="w-full"
          onChange={updateUserInfo}
        />
        </div>

        <button
          type="submit"
          className="mx-auto mt-2 mb-8 w-fit rounded-lg bg-slate-100 p-2 outline-none hover:cursor-pointer hover:bg-slate-300"
        >
          Update Event
        </button>
      </form>
    </div>
  );
}



//Diamonds
//number of Members 
//Individual Member Diamonds

//Challenges

//Route Photo for profile picture (avatar) , cover photo, 




