import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import { auth,db } from "../../firebase/firebase";
import {useState } from "react";
import {uploadImage, createCircle, phoneLogin } from "../../firebase/firebase-calls";
import toast from "react-hot-toast";
import { joinCircle } from "../../firebase/firebase-calls";
import "./event_styles.css";

// signup
import { signInWithPhoneNumber,RecaptchaVerifier } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function SignUpPop({
  setShowModal,
  userData,
  setUserData,
  choice,
  circleData,
  joining
}) {
  const { user } = useSelector((state) => state.user);
  const currentUser = auth?.currentUser;
  const navigate = useNavigate();

  let userAuth;


  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    title: "",
    adddress: "",
    description: "",
    costs: "",
    coverPic: ""
  });

  const updateUserInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserImage = async (e) => {
    const file = e.target.files[0];
    const field = e.target.name;
    const loader = toast.loading("uploading image");
    const path = `images/${currentUser.uid}/${file.name}`;
    const fieldURL = await uploadImage(path, file);
    toast.success("uploaded image", { id: loader });
    setUserInfo({ ...userInfo, [field]: fieldURL });
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    await setUserData((prev) => ({
     title: userInfo?.title,
     address: userInfo?.address,
     descriptions: userInfo?.descriptions,
     costs: userInfo?.costs,
     pic: userInfo?.coverPic

    }));
    setShowModal(false);
    createCircle(
      currentUser,
      userInfo,)
  };
//signup


const signin = () => {
  // if (mynumber === "" || mynumber.length < 10) return;
  console.log(mynumber)
  let verify = new RecaptchaVerifier('recaptcha-container',{'size': 'invisible'}, auth);

 // change flow of signin

  userAuth = signInWithPhoneNumber(auth, "+1" + mynumber, verify).then((result) => {
      setfinal(result);
      toast.success("Sent Verifcation Code")
      setshow(true);
  })
      .catch((err) => {
          toast.error(err);
          window.location.reload();
      });
}
  
const ValidateOtp = () => {
  if (otp === null || final === null)
      return;
  final.confirm(otp).then((result) => {
      toast.success("Successful! Logging in")
      localStorage.setItem("authToken", result.user.accessToken);
      console.log(result)
      //check if signedup yet if have, walk on in
      if (result.user.metadata.creationTime == result.user.metadata.lastSignInTime){
        phoneLogin("+1"+ mynumber, result.user.uid, name, dispatch)
      }
      setShowModal(false)
      if (joining) {joinCircle(result.user, circleData, choice)}
      else{navigate("/home")}

        
    }).catch((err) => {
      console.log(err)
      console.log("final: " + JSON.stringify(final) + ", OTP: " + otp + "userAuth+ " + userAuth)
        toast.error("Wrong Code")
    })
  }
  function handleOnChange(value) {
    setName(value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name) {
      toast.error('Name input cannot be empty!');
    } else {
      // Submit the form
      signin();
    }
  };

  const [mynumber, setnumber] = useState("+1");
  const [otp, setotp] = useState('');
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState('');
  const [name, setName] = useState('');


 

  return (
    <div className="blue_bg flex h-full w-full">
      <div
        className="blue_bg text-white fontInter flex h-full w-full fixed top-0 left-0 right-0 mx-auto flex-col  p-4 z-20 rounded-none"
      >
        <h1 className="text-white text-3xl mt-12 font-bold">Almost there...</h1>
        <AiFillCloseCircle
          className="absolute right-2 top-2 z-10 rounded-full bg-slate-100 text-3xl text-gray-500 hover:cursor-pointer  hover:brightness-90"
          onClick={() => setShowModal((prev) => !prev)} 
        />

        <p className="mt-4 text-xl"> {choice}</p>
        <p className="text-gray-400 text-sm">Confirm your name and phone number</p>
        <div>
          <form onSubmit= {handleSubmit}>

            <br/><br/>
            <div class="group border-b border-gray border-solid w-[350px]">
              <input class="input" type="text" required onChange={(e) => { 
              setnumber(e.target.value) }}
              className="shadow py-2 px-3 text-white w-[350px]"
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label class="label">Phone Number</label>
            </div>

            <br /><br />
            <div class="group border-b border-gray border-solid w-[350px]">
              <input type="text" id="name" name="name" required className="shadow py-2 px-3 text-white w-[350px]"
              onChange={(e) => { handleOnChange(e.target.value) }}/>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label class="label">Name</label>
            </div>

            
            <div id="recaptcha-container"></div>

            <div className="flex w-full justify-center">
            <button className="rounded-lg bg-slate-100 mt-4 p-2 outline-none text-black hover:cursor-pointer hover:bg-slate-300" type="submit">Send Verification Code</button>
            </div>
          
          </form>
        </div>
        <div>
            <input type="text" placeholder={"Enter your code"}
              onChange={(e) => { setotp(e.target.value) }}
              className="mt-8 shadow appearance-none border rounded-lg w-full grey_bg py-2 px-3 text-white">
            </input>
            <br /><br />
            <div className="flex w-full justify-center">
            <button className="mx-auto mt-2 w-fit rounded-lg bg-slate-100 text-black p-2 outline-none hover:cursor-pointer hover:bg-slate-300" onClick={ValidateOtp}>Verify</button>
            </div>
        </div>
       
     
      </div>
    </div>
  );
}
