import { useState } from "react";
import { Link } from "react-router-dom";

export function ResponseModal({ responses, users, event }) {
    const [isOpen, setIsOpen] = useState(false);
    const responseString = JSON.stringify(responses);
    const usersString = JSON.stringify(users);
    const eventString = JSON.stringify(event);



    
    function toggleModal() {
      setIsOpen(!isOpen);
    }
  
    return (
      <>
        <div className="ml-8 flex flex-row font-extrabold">
        <button
          onClick={toggleModal}
          className="flex items-center self-center purple_bg text-white h-8 w-8 rounded-full"
          type="button"
        > 
        <p className="mx-auto text-white text-sm">+{responseString}</p>

        </button>
        <button>
        <p 
            onClick={toggleModal}
            className="ml-1 text-white text-15p font-extrabold self-center">Going</p>
        </button>

        </div>

        
        {/* Modal backdrop */}
        {isOpen && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-50"></div>
        )}
  
        {/* Modal */}
        <div
          className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center ${
            isOpen ? "" : "hidden"
          }`}
        >
          <div className="blue_bg text-black h-2/3 rounded-lg shadow-lg w-80 max-w-md mx-auto overflow-y-scroll">
            {/* Modal header */}
            <div className="flex items-center justify-between px-6 py-4 bg-gray-100 border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p className="text-xl font-semibold text-gray-800">RSVP List</p>
              <button
                onClick={toggleModal}
                type="button"
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                aria-label="Close"
              >
                <svg
                  className="h-6 w-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="heroicon-ui"
                    d="M6.7 5.3a1 1 0 00-1.4 1.4L10.6 12l-5.3 5.3a1 1 0 101.4 1.4L12 13.4l5.3 5.3a1 1 0 001.4-1.4L13.4 12l5.3-5.3a1 1 0 00-1.4-1.4L12 10.6 6.7 5.3z"
                  />
                </svg>
              </button>
            </div>
  
            {/* Modal body */}
            <div className="px-4 py-4 blue_bg ">
                <ul className="mx-auto flex flex-col text-center items-center font-light">
                    {event.memberCount?.map((member) => ( 
                        <div className="rounded-lg pt-1 font-semibold text-left w-full flex flex-row text-white">
                        <Link to={`/profile/${member?.uid}`} class="flex items-center">
                        <img
                            src={users.find(user => user?.uid === member?.uid)?.coverPic}
                            alt=""
                            className="h-7 aspect-square rounded-full"
                        />
                        <p className="text-left font-extrabold"> &nbsp;{users.find(user => user?.uid === member?.uid)?.name || "Anonymous"} &nbsp;is a {member?.response}</p>
                        </Link>
                        </div>
                    ))}
                </ul>

            </div>
  
            {/* Modal footer */}
            <div className="flex items-center justify-end px-6 py-4 blue_bg border-t border-gray-200 rounded-bl-lg rounded-br-lg">
              <button
                onClick={toggleModal}
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
}