import { NavLink } from "react-router-dom";
import { navLinks } from "utils/Constants";
import { useSelector } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import  Person2Icon  from '@mui/icons-material/Person2';
import { CreateEventModal } from "pages/pages";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
export default function Sidebar() {
  const { user } = useSelector((state) => state.user);
  const [showModalExp, setShowModalExp] = useState(false);


  { /* Code for hide on scroll 
  const body = document.body;
  let lastScroll = 0;
  window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset
    
    if (currentScroll <= 0) {
      body.classList.remove("scroll-up")
    }

    if (currentScroll > lastScroll && !body.classList.contains("scroll-down") && !showModalExp) {
      body.classList.remove("scroll-up")
      body.classList.add("scroll-down")
    }

    if (currentScroll < lastScroll && body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-down")
      body.classList.add("scroll-up")
    }
    lastScroll = currentScroll;
  }) 
  */ }

  return (
    <div>
    <navbar>
    <div className="fixed justify-evenly md:justify-start border-white bottom-0 md:left-0 md:top-0 md:flex-col z-40 my-0 mx-0 md:max-w-[14vw] md:fixed flex md:min-h-screen w-full items-center user_bg px-2 py-1 text-gray-700 md:border-r border-solid">
      {navLinks.map(({ pathTo, icon, img, navPath }) => (
        <NavLink
          to={pathTo}
          className="flex hover:scale-105 text-white focus:text-purple-300 sm:text-normal max-w-1 items-center rounded-md px-2 py-1 text-xl sm:flex-col sm:gap-1 md:flex-row md:w-full md:mt-24"
        >
          {icon}
          <p className="hidden sm:block sm:text-xs md:block md:text-sm lg:block lg:text-xl xl:inline ">
          {navPath}
          </p>
        </NavLink>
      ))}

{/* button create event */}
<NavLink className="relative md:w-full" to="/create">
<button class="md:hidden flex nav_purple justify-center text-center items-center text-white text-3xl px-4 border rounded-lg"  > 
      +
</button>

      <div className="hidden md:flex md:relative md:left-0 md:flex-row hover:scale-105 text-white focus:text-purple-300 max-w-1 items-center rounded-md px-2 py-1 text-xl md:w-full">
        <AddBoxIcon className="h-[40px] w-[40px]"></AddBoxIcon>
      <button className="hidden md:block md:left-0 md:text-xl md:text-white" >Create event</button>
      </div>
</NavLink>
     
{/* for create event screen */}
{/* <NavLink
        to="/create"
        className="md:hidden flex nav_purple justify-center text-center items-center text-white text-3xl px-4 border rounded-lg"
      >
       
       <div className="hidden md:flex-row md:flex hover:scale-105 text-white focus:text-purple-300 max-w-1 items-center rounded-md px-2 py-1 text-xl md:w-full">
        <AddBoxIcon className="h-[40px] w-[40px]"></AddBoxIcon>
      <button className="hidden md:block md:text-xl md:text-white" onClick={() => setShowModalExp((prev) => !prev)} >Create event</button>
      </div>

      </NavLink> */}

{/* profile screen */}
      <NavLink
        to="/"
        className="flex hover:scale-105 text-white focus:text-purple-300 sm:text-normal max-w-1 items-center rounded-md px-2 py-1 text-xl sm:flex-col sm:gap-1 md:flex-row md:w-full"
      >
        {user?.coverPic ? (
          <img
            src={user?.coverPic}
            alt="user-dp"
            className="aspect-square h-[40px] w-[40px] rounded-full object-cover"
          />
        ) : (
          <AccountCircleIcon className="h-[40px] w-[40px]"/>
        )}
        <p className="hidden sm:block sm:text-xs md:block md:text-sm lg:block lg:text-xl xl:block">
          Profile
        </p>
      </NavLink>

    </div>
    </navbar>
    {showModalExp && (
        <div className="h-full w-full">
          <div className="fixed flex h-screen w-full items-center justify-center opacity-70"></div>
          <CreateEventModal
            setShowModal={setShowModalExp}
          />
        </div>
      )}
    </div>
  );
}
