import { useState,useEffect } from "react";
import { FiEdit3, FiTrash, FiCheck, FiHeart } from "react-icons/fi";
import { RiInboxArchiveLine, RiInboxUnarchiveLine } from "react-icons/ri";
import { auth } from "../firebase/firebase";
import { Comment } from "./components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {
  archivePost,
  bookmarkPost,
  deletePost,
  dislikePost,
  editPost,
  likePost,
  postComment,
  unarchivePost,
  undoBookmarkPost,
  uploadImage,
  getUser
} from "../firebase/firebase-calls";
import {
  FaHeart,
  FaRegCommentAlt,
  FaRegBookmark,
  FaBookmark,
} from "react-icons/fa";

export default function Questions({ post, currentCircle }) {
  const currentUser = auth?.currentUser;
  const { allUsers } = useSelector((state) => state.allUsers);
  const { user } = useSelector((state) => state.user);
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableComments, setEnableComments] = useState(false);
  const [comment, setComment] = useState("");
    const [userData, setUserData] = useState([]);

  const [updatePost, setUpdatePost] = useState({
    caption: post?.caption,
    imageURL: post?.imageURL,
  });


  const editPostHandler = () => {
    editPost(post, updatePost);
    setEnableEdit(false);
  };

  const handlePostImage = async (e) => {
    const file = e.target.files[0];
    const loader = toast.loading("uploading image");
    const path = `images/${currentUser.uid}/${file.name}`;
    const imageURL = await uploadImage(path, file);
    toast.success("uploaded image", { id: loader });
    setUpdatePost((prev) => ({
      ...prev,
      imageURL: imageURL,
    }));
  };

  const toggleLike = ({ likes, postID }) => {
    const userExists = likes?.some((user) => user.userID === currentUser?.uid);
    userExists
      ? dislikePost(postID, currentUser)
      : likePost(postID, currentUser);
  };

  const toggleArchive = (post) => {
    const isArchived = user?.archives?.some(
      ({ postID }) => postID === post?.postID
    );
    isArchived
      ? unarchivePost(post, currentUser)
      : archivePost(post, currentUser);
  };

  const toggleBookmark = (post) => {
    const isBookmarked = user?.bookmarks?.some(
      ({ postID }) => postID === post?.postID
    );
    isBookmarked
      ? undoBookmarkPost(post, currentUser)
      : bookmarkPost(post, currentUser);
  };

  useEffect(
    () => {
      if (userData.length == 0) {
        getUser(currentUser, setUserData);

      }
      console.log("User Fetched!")
    },
    // eslint-disable-next-line
    [currentUser]
  );

  return (
    <div className="mx-6 md:w-[630px] md:mx-auto mt-2 mb-36 flex h-fit flex-col rounded-lg">

      <div className="ml-2 md:mx-8">

          <p className="text-lg text-white interFont font-extrabold">Chat</p>

      


          <div className="relative w-full py-2 discussion_bg rounded-md mt-2">
            <div className="rounded-lg w-full h-[15vh] px-4 py-4 text-sm focus:outline-none">
            <textarea
              placeholder="Drop a comment.."
              className="w-[70%] h-[100%]"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            </div>

            <button
              className="absolute right-8 top-12 rounded-md border-none purple_bg px-4 py-2 text-md  text-white fontInter"
              onClick={() => {
                if (comment) {
                  postComment(post, comment, userData, currentCircle);
                  setComment("");
                }
                else {
                  toast.error("Empty comment!");
                }
              }}
            >
              Post
            </button>
          </div>
          
          {post?.comments.length == 0 ? (<div className=""></div>) : (post?.comments?.map((comment) => (
            <Comment comment={comment} post={post} key={comment} />
          ))) }
      </div>
      
    </div>
  );
}
