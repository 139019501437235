import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import { auth } from "../../firebase/firebase";
import { useEffect, useState } from "react";
import { experienceAdd, uploadImage, createCircle, getUser } from "../../firebase/firebase-calls";
import toast from "react-hot-toast";
import {useNavigate } from "react-router-dom";

//time picker
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";
//calendar
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import Calendar from '@hassanmojab/react-modern-calendar-datepicker';
import styled from "styled-components";

import location from "assets/location.png";
import calendar from "assets/calendar.png";

export default function CreateEventModal({

}) {
  console.log("Experience")
  const { user } = useSelector((state) => state.user);
  const currentUser = auth?.currentUser;
  const navigate = useNavigate();

  const [creatorData, setCreatorData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [startTime, setStart] = useState();
  const [endTime, setEnd] = useState();



const input = document.getElementById('create_input');
const [inputValue, setInputValue] = useState('');
const maxLength = 20;

const renderCustomInput = ({ ref }) => (
<div className="eventRow rounded-lg flex mt-2 mx-4 p-2 w-[84vw] md:w-[560px] focus:outline text-white focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-4">
  <img src={calendar} className="w-[15px] h-[17px] mr-4 my-auto flex"></img>
  <input
    readOnly
    ref={ref} // necessary
    placeholder="Date"
    value={selectedDay ? `${selectedDay.month}-${selectedDay.day}-${selectedDay.year}` : ''}
    className="w-full"
  />
</div>


)
  const [userInfo, setUserInfo] = useState({
    title: "",
    address: "",
    description: "",
    costs: "",
    coverPic: "https://firebasestorage.googleapis.com/v0/b/jooby-b9791.appspot.com/o/images%2Fundefined%2Fevent_default.png?alt=media&token=1b1c2566-9869-490a-a464-c58d050352ed",
    date: "",
    startTime: "",
    endTime: "",
  });
  // const [value, setValue] = useState(
  //   dayjs(new Date())
  // );

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  const updateEventNameInfo = (e) => {
    const { name, value } = e.target;
    const num_value = e.target.value;

    if (num_value.length <= maxLength) {
      setInputValue(num_value);
      setUserInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  const updateUserInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserImage = async (e) => {
    const file = e.target.files[0];
    const field = e.target.name;
    const loader = toast.loading("uploading image");
    const path = `images/${currentUser.uid}/${file.name}`;
    const fieldURL = await uploadImage(path, file);
    toast.success("uploaded image", { id: loader });
    setUserInfo({ ...userInfo, [field]: fieldURL });
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    // setShowModal(false);
    // console.log("value:" + value)
    createCircle(
      creatorData,
      userInfo,
      new Date(`${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`),
      startTime,
      endTime)
    navigate("/")


      //create first post for comments
   
  };
  useEffect(
    () => {
      if (creatorData.length == 0) {
        getUser(currentUser, setCreatorData);
      
      }
      console.log("User Fetched!")
    },
    // eslint-disable-next-line
    [currentUser]
  );


  
 

  return (
    <div className="flex h-full w-full flex-col blue_bg">
      <form
        className="flex-col top-0 left-0 flex p-4  md:w-[630px] md:flex md:left-0 md:right-0 md:mx-auto h-full w-full overflow-y-hidden"
        onSubmit={updateUserData}
      >
        {/* <AiFillCloseCircle
          className="absolute right-2 top-2 z-10 rounded-full bg-slate-50 text-3xl text-gray-500 hover:cursor-pointer  hover:brightness-90"
          onClick={() => setShowModal((prev) => !prev)}
        /> */}
        <div className="relative w-full">
          <h3 className="text-white fontInter text-19p w-full text-center mt-2">Create an event</h3>
          <h3 className="text-white fontInter text-15p ml-4 fontInter mt-4">Add cover photo</h3>
          

          <div  className="left-4 mt-4 cursor-pointer md:cursor-default relative aspect-[16/9] h-[120px] w-[120px] md:h-1/3 md:w-1/3 hover:scale-105">
          <label
            htmlFor="coverPic"
           
          >
            <input
              type="file"
              id="coverPic"
              name="coverPic"
              accept="image/*, .gif"
              className="hidden"
              onChange={handleUserImage}
            />          
            <img 
            src={userInfo?.coverPic ? userInfo?.coverPic : ""}
            alt="No Photo Selected"
            className="object-cover h-full w-full cursor-pointer rounded-lg"
          />
          </label>
          </div>


        

          {/* <div className="absolute top-3/4 right-1/2 mx-auto flex h-fit w-80 max-w-[90%] translate-x-1/2 flex-col items-center gap-2 rounded-lg bg-slate-50 py-3 px-2 shadow sm:top-2/3 md:top-3/4 md:w-2/3 lg:w-80 xl:-bottom-1/2">
            
          </div> */}
        
        <div className="flex mx-4 mt-2 justify-end text-gray-400">      {inputValue.length === maxLength && (
        <div className="warning-message">Character limit reached</div>
      )}</div>
        <div className="eventRow rounded-lg flex my-4 mx-4 mt-4 p-2 focus:outline text-white focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-4">
        <input
          id="create_input"
          type="text"
          name="title"
          placeholder="Event Name"
          onChange={updateEventNameInfo}
          value={inputValue}
          className="w-full"
          maxlength="20"
        />
        </div>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
    
        <DateTimePicker
        className="grey_bg rounded-md flex m-2 p-2"
        renderInput={(props) => <TextField {...props} />}
        label="DateTimePicker"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        />
            </LocalizationProvider> */}


        <div className="mt-2">

          <div className="flex flex-row mx-4 justify-between text-white">
            <div className="relative flex flex-col">
              <h3 className="relative left-4">Start Time</h3>
            <TimePicker
              showSecond={false}
              className="w-[133px] h-[44px]"
              onChange={(date) => {
                setStart(date);
              }}
              // hideDisabledOptions
              minuteStep={15}
              // {...others}
              value={startTime}
              use12Hours
            />
            </div>

            <div className="flex flex-col relative">
              <h3 className="relative left-4">End time</h3>
              <TimePicker
                showSecond={false}
                className="w-[133px] h-[44px] "
                onChange={(date) => {
                  setEnd(date);
                }}
                // hideDisabledOptions
                minuteStep={15}
                // {...others}

                value={endTime}
                use12Hours
              />
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '0', height: '150px', width: '100%'}}>
            <Calendar
              value={selectedDay}
              onChange={setSelectedDay}
              renderInput={renderCustomInput} // render a custom input
              shouldHighlightWeekends
              colorPrimary="#9c88ff" // added this
              />
          </div>
            <div className="eventRow rounded-lg flex mx-4 p-2 focus:outline text-white focus:outline-1 focus:outline-slate-400">
              <img src={location} className="w-[15px] h-[20px] flex my-auto mr-4"></img>
              <input
                type="text"
                name="costs"
                placeholder="Location"
                onChange={updateUserInfo}
                className="w-full"
              />
            </div>
          
          <div className="eventRow rounded-lg mt-12 mx-4 p-2 focus:outline-1 focus:outline-slate-400 h-[110px]">
          <textarea
            type="text"
            name="description"
            placeholder="Description"
            className="eventRow text-white h-full w-full sfocus:outline focus:outline-1 focus:outline-slate-400 sm:mt-16 md:mt-2"
            onChange={updateUserInfo}
            style={{ height: "" }} // Set the height to 200 pixels

          />
          </div>
        </div>
        </div>
       



        <button
          type="submit"
          className="text-black mx-auto mt-4 mb-20 w-fit rounded-lg purple_bg fontInter text-shadow p-2 outline-none hover:cursor-pointer hover:bg-slate-300"
        >
          Post Event
        </button>
      </form>
    </div>
  );
}
