import { useState } from "react";
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const options = { month: 'long', day: 'numeric' , hour: 'numeric', minute: 'numeric'};

function formatAMPM(date1, date2) {
  var hours1 = date1.getHours();
  var hours2 = date2.getHours();
  var ampm1 = hours1 >= 12 ? 'pm' : 'am';
  hours1 = hours1 % 12;
  hours1 = hours1 ? hours1 : 12; // the hour '0' should be '12'

  console.log(hours2);
  var ampm2 = hours2 >= 12 ? 'pm' : 'am';
  hours2 = hours2 % 12;
  hours2 = hours2 ? hours2 : 12; // the hour '0' should be '12'

  var strTime = hours1 + ' ' + ampm1 + ' - ' + hours2 + ' ' + ampm2;
  return strTime;
}


const ToggleEventModal = ({upcoming, past, my_events}) => {
  const [activeTab, setActiveTab] = useState(1);
  const months = ["JAN", "FEB", "MAR", "APR", "May", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];

  return (
    <div className="relative w-full mb-8">
      {/* Modal background */}
      <div className="flex"></div>

      {/* Modal content */}
      <div className="text-white user_bg ">
        {/* Tab buttons */}
        <div className="flex mb-4 justify-evenly space-x-2 mx-2">
          <button
            className={`${
              activeTab === 1 ? "border-custom_purple bg-custom-purp bg-opacity-20 text-white font-semibold text-sm" : "border-white text-sm"
            } border-solid border px-4 py-2 rounded-full hover:scale-105`}
            onClick={() => setActiveTab(1)}
          >
            Upcoming
          </button>
          <button
            className={`${
              activeTab === 2 ? "border-custom_purple bg-custom-purp bg-opacity-20 text-white font-semibold text-sm" : "border-white text-sm"
            } border-solid border px-4 py-2 rounded-full hover:scale-105`}
            onClick={() => setActiveTab(2)}
          >
            Past
          </button>
          <button
            className={`${
              activeTab === 3 ? "border-custom_purple bg-custom-purp bg-opacity-20 text-white font-semibold text-sm" : "border-white text-sm"
            } border-solid border px-4 py-2 rounded-full hover:scale-105`}
            onClick={() => setActiveTab(3)}
          >
            My Events
          </button>
        </div>

        {/* Tab content */}
        <div className="interFont md:bg-inherit w-full blue_bg">
          {activeTab === 1 && 
          <div className="">
            <h5 className="ml-6 mx-auto h-fit font-bold text-slate-300 py-4">Upcoming Events</h5>
            <ul className="mt-4 w-full mx-auto flex flex-1 flex-col text-white space-y-4 mb-36 h-[37vh] overflow-y-auto overflow-x-hidden">

            {upcoming?.length > 0 ? (
              upcoming?.map((circles) => (
                <Link className="button rounded-lg"
                to={`/circle/${circles?.circleID}`}
                >
                {/* Each individual circle container */}
                <div className="mx-6 sm:text-sm eventRow rounded-lg flex flex-row h-[57px]">
                    <div className="">
                    <img src={circles.pic} 
                    alt="" 
                    className = "blue_bg h-[57px] min-w-[57px] max-w-[57px] object-cover hover:opacity-70 rounded-lg"/>
                    </div>

                    <div className="ml-4 mt-2 w-full">
                    <h5 className="font-bold text-left mx-auto text-sm">
                        {circles.circleName}
                    </h5>

                    <div className="flex flex-row space-x-1 items-center">
                    <AccessTimeFilledIcon className="max-h-[15px] max-w-[15px]"></AccessTimeFilledIcon>
                    <p className="text-gray-400 text-xs">{formatAMPM(new Date(circles?.start), new Date(circles?.end))}&nbsp; </p>
                    </div>


                    </div>
                    <div className="my-auto min-w-[60px]  flex flex-col mx-auto">
                    <p className="text-white text-xs text-center">{months[circles?.date.toDate().getMonth()]}&nbsp; </p>
                    <p className="text-lg text-white text-center">{circles?.date.toDate().getDate()}&nbsp; </p>
                    </div>
                </div>
            </Link>
            )) )
          : (
            <div className="h-[30vh] w-full ml-6 overflow-none"><p className="text-sm md:text-md">Find Events to RSVP for on the <a href="/home" className="underline text-blue-300">home screen</a></p></div>

          )
        }
            </ul>

       </div>}


          {activeTab === 2 && <div>
          <h5 className="ml-6 mb-2 mx-auto font-bold text-slate-300 py-4">Past Events</h5>
          <ul className="mt-4 w-full mx-auto flex flex-1 flex-col text-white space-y-4 mb-36 h-[46vh] overflow-y-auto overflow-x-hidden">
            {past?.length > 0 ? (
            past?.map((circles) => (
                <Link className="button rounded-lg"
                to={`/circle/${circles?.circleID}`}
                >
                {/* Each individual circle container */}
                <div className="mx-6 sm:text-sm eventRow rounded-lg flex flex-row h-[57px]">
                    <div className="h-[57px] w-[57px]">
                    <img src={circles.pic} 
                    alt="" 
                    className = "h-[57px] min-w-[57px] max-w-[57px] object-cover hover:opacity-70 rounded-lg"/>
                    </div>
                    <div className="ml-4 mt-2 w-full">
                    <h5 className="font-bold text-left mx-auto text-sm">
                        {circles.circleName}
                    </h5>

                    <div className="flex flex-row space-x-1 items-center">
                    <AccessTimeFilledIcon className="max-h-[15px] max-w-[15px]"></AccessTimeFilledIcon>
                    <p className="text-gray-400 text-xs">{formatAMPM(new Date(circles?.start), new Date(circles?.end))}&nbsp; </p>
                    </div>

                    </div>
                    <div className="my-auto min-w-[60px]  flex flex-col mx-auto">
                    <p className="text-white text-xs text-center">{months[circles?.date.toDate().getMonth()]}&nbsp; </p>
                    <p className="text-lg text-white text-center">{circles?.date.toDate().getDate()}&nbsp; </p>
                    </div>
                </div>
            </Link>
            ))
          )
          : (
            <div className="h-[30vh] w-full ml-6"><p className="text-sm md:text-md">Find Events to RSVP for on the <a href="/home" className="underline text-blue-300">home screen</a></p></div>
          )
          }
          </ul>
      </div>}


          {activeTab === 3 && <div>      
            
            
    <h5 className="ml-6 mb-2 mx-auto font-bold text-slate-300 py-4">My Events</h5>
    <ul className="mt-4 w-full mx-auto flex flex-1 flex-col text-white space-y-4 mb-36 h-[37vh] overflow-y-auto overflow-x-hidden">
     {my_events?.length > 0 ? (
     my_events?.map((circles) => (
         <Link className="button rounded-lg"
         to={`/circle/${circles?.circleID}`}
         >
           {/* Each individual circle container */}
           <div className="mx-6 sm:text-sm eventRow rounded-lg flex flex-row h-[57px]">
             <div className="">
               <img src={circles.pic} 
               alt="" 
               className = "h-[57px] min-w-[57px] max-w-[57px] object-cover hover:opacity-70 rounded-lg"/>
             </div>

             <div className="ml-4 mt-2 w-full">
                    <h5 className="font-bold text-left mx-auto text-sm">
                        {circles.circleName}
                    </h5>

                    <div className="flex flex-row space-x-1 items-center">
                    <AccessTimeFilledIcon className="max-h-[15px] max-w-[15px]"></AccessTimeFilledIcon>
                    <p className="text-gray-400 text-xs">{formatAMPM(new Date(circles?.start), new Date(circles?.end))}&nbsp; </p>
                    </div>


                    </div>
                    <div className="my-auto min-w-[60px]  flex flex-col mx-auto">
                    <p className="text-white text-xs text-center">{months[circles?.date.toDate().getMonth()]}&nbsp; </p>
                    <p className="text-lg text-white text-center">{circles?.date.toDate().getDate()}&nbsp; </p>
                    </div>
           </div>
       </Link>
       ))
      )
      : (
        <div className="h-[40vh] w-full mx-auto flex flex-col"><p className="text-sm md:text-md text-center">No Hosted Events Yet</p>
          {/* button create event */}
          <NavLink className="relative flex md:w-full" to="/create">
            <button class="px-4 py-2 purple_bg rounded-md mx-auto mt-2">Create Event</button>
          </NavLink>
        </div>
      )
      }
    </ul>
       </div>}
        </div>
      </div>
    </div>
  );
};
export default ToggleEventModal;
