import { Route, Routes } from "react-router-dom";
import RequiresAuth from "components/RequiresAuth";
import {
  Home,
  Explore,
  UserProfile,
  OtherProfile,
  Error,
  Login,
  Signup,
  Resume,
  Circles,
  CircleMainPage,
  About,
  ChatGPT,
  EditProfileModal,
  Dashboard,
  CreateEventModal
} from "pages/pages";


export default function Router() {
  return (
    <Routes>
      <Route element={<RequiresAuth />}>
        {/* Remove / from Home */}
        <Route path="/" element={<UserProfile />} />
        <Route path="/home" element={<Home />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/create" element={<CreateEventModal />} />


        







        <Route path="/explore" element={<Explore />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/profile/:profileID" element={<OtherProfile />} />
         <Route path="/resume" element={<Resume />} /> 
         <Route path="/circle" element={<Circles />} /> 
         <Route path="/circleMainPage" element={<CircleMainPage />} /> 
         <Route path="/about" element={<About />} /> 
         <Route path="/circle/:circleID" element={<Circles />} />
         <Route path="/eprofile" element={<EditProfileModal />} />
         <Route path="/assist" element={<ChatGPT/>} />




      </Route>
      <Route path="/login" element={<Login />} />
      {/* <Route path="/aboutLaunch" element={<LandingAbout />} />
      <Route path="/signup" element={<Signup />} /> */}
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
